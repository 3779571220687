/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import PauseIcon from "@mui/icons-material/Pause";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import GoogleMapReact from "google-map-react";
import LogOutIcon from "../../asset/icon/logout-icon.svg";
import RNONE from "../../asset/icon/Rental-Board-v1.svg";
import RNTWO from "../../asset/icon/Rental-Board-v2.svg";
import DeliveryReceipt from "../../asset/icon/Delivery-Receipt.svg";
import RentalBord from "../../asset/icon/Rental-Board.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  checkboxClasses,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { dashboardService } from "../../Services/dashboardService";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CustomLoader from "../CustomLoader/customLoader";
import { setLocalStorageData } from "../../Utils/helpers";
import { SELECT_BOARD } from "../../Utils/contasts";
import moment from "moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const AnyReactComponent = ({
  text,
  item,
  onClick,
  selectPoPupMarker,
  closePopUp,
}) => (
  <div className="Map_Pin_Div">
    {selectPoPupMarker?.PositionLatitude === item?.PositionLatitude &&
    selectPoPupMarker?.PositionLongitude === item?.PositionLongitude ? (
      <div class="help-tip">
        <p>
          <div className="custom_pop_up">
            <div className="custom_pop_up_close_icon_box">
              {" "}
              <img
                src="./close-small.png"
                alt=""
                className="custom_pop_up_close_icon"
                onClick={closePopUp}
              />
            </div>
            <div className="custom_pop_up_head_1">
              {selectPoPupMarker?.CompanyName}
            </div>
            {/* <div className="custom_pop_up_head_2">Location Name : ""</div> */}
            {/* <div className="custom_pop_up_head_3">Address : ""</div> */}
            <div className="custom_pop_up_head_3">
              Year: {selectPoPupMarker?.Year}
            </div>
            <div className="custom_pop_up_head_3">
              MakeName: {selectPoPupMarker?.MakeName}{" "}
            </div>
            <div className="custom_pop_up_head_3">
              Model: {selectPoPupMarker?.Model}
            </div>
            <div className="custom_pop_up_head_3">
              SerialNumber: {selectPoPupMarker?.SerialNumber}
            </div>
            <div className="custom_pop_up_head_3">
              PositionDateTime:{" "}
              {moment(selectPoPupMarker?.PositionDateTime).format("llll")}
            </div>
          </div>
        </p>
      </div>
    ) : (
      ""
    )}
    <p className="Map_Pin_Img_Text">{item?.AssetDescription}</p>
    <div onClick={onClick}>
      {moment(new Date().toJSON()).diff(
        moment(item?.PositionDateTime),
        "days"
      ) <= 7 ? (
        <img
          src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          alt=""
          width={"48px"}
          height={"48px"}
        />
      ) : (
        <img
          src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          alt=""
          width={"48px"}
          height={"48px"}
        />
      )}
    </div>
  </div>
);

const DashboardComponent = () => {
  const mapDefaultZoom = 5;
  const [selectPoPupMarker, setSelectPoPupMarker] = React.useState();

  const [selectRentedMapItem, setSelectRentedMapItem] = React.useState({
    lat: "",
    lng: "",
    zoom: mapDefaultZoom,
  });
  const [autoNextPageMap, setAutoNextPageMap] = React.useState(true);
  const [rentedMapDataCurrontPage, setRentedMapDataCurrontPage] =
    React.useState(1);
  const [intervalIdMap, setIntervalIdMap] = React.useState(null);
  const [rentedMapDataLength, setRentedMapDataLength] = React.useState(0);
  const [rentedMap, setRentedMap] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);
  const [settingValue, setSettingValue] = React.useState(null);
  const [totalAssetsRecode, setTotalAssetsRecode] = React.useState({});
  const [settingValueLoader, setSettingValueLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectStatuesModel, setSelectStatuesModel] = React.useState(false);
  const [storeList, setStoreList] = React.useState([]);
  const [openSwitchV1Model, setOpenSwitchV1Model] = React.useState(false);
  const [navigationColorColor, setNavigationColorColor] = React.useState([
    {
      color: "#863B96",
      use: false,
    },
    {
      color: "#00b33c",
      use: false,
    },
    {
      color: "#FFC80F",
      use: false,
    },
    {
      color: "#ac7339",
      use: false,
    },
    {
      color: "#1a75ff",
      use: false,
    },
    {
      color: "#fccde2",
      use: false,
    },
    {
      color: "#a7ff83",
      use: false,
    },
    {
      color: "#88bef5",
      use: false,
    },
    {
      color: "#e8ffe8",
      use: false,
    },
    {
      color: "#a56cb4",
      use: false,
    },
    {
      color: "#775ada",
      use: false,
    },
    {
      color: "#278ea5",
      use: false,
    },
    {
      color: "#ffb174",
      use: false,
    },
  ]);
  const navigate = useNavigate();
  const [showAssetCount, setShowAssetCount] = React.useState(
    localStorage.getItem("rowPerPage") ?? 10
  );

  const [showAssetCountMap, setShowAssetCountMap] = React.useState(
    localStorage.getItem("rowPerPage-Rental-Map") ?? 200
  );

  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [storeCodeSelect, setStoreCodeSelect] = React.useState(
    localStorage.getItem("StoreCode") ?? ""
  );
  const [autoNextPage, setAutoNextPage] = React.useState(true);
  const [timeAutoNextPage, setTimeAutoNextPage] = React.useState(false);
  const [
    assetCategorizationSummaryDMSLoader,
    setAssetCategorizationSummaryDMSLoader,
  ] = React.useState(true);
  const [loader, setLoader] = React.useState({
    AssetCategorizationSummaryDMSLoader: true,
    AssetStatusesLoader: true,
  });
  const [openSwitchModel, setOpenSwitchModel] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [sideBarMenu, setSideBarMenu] = React.useState(false);
  const [assetList, setAssetList] = React.useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });
  const [dashboardLoader, setDashboardLoader] = React.useState(true);
  const [AssetTypeList, setAssetTypeList] = React.useState([]);
  const [assetStatuses, setAssetStatuses] = React.useState(
    JSON.parse(localStorage.getItem("DisplayStatues-V1")) ?? []
  );
  const [assetDisplayStatues, setAssetDisplayStatues] = React.useState([]);
  const [assetStatusesDetails, setAssetStatusesDetails] = React.useState([]);
  const [assetStatusesListPagination, setAssetStatusesListPagination] =
    React.useState([]);
  const [showSideBar, setShowSideBar] = React.useState(true);
  const [DisplayStatuesLocalStorage, setDisplayStatuesLocalStorage] =
    React.useState(JSON.parse(localStorage.getItem("DisplayStatues-V1")));

  const [onRentMashinListings, setOnRentMashinListings] = React.useState([]);
  const [onRentMashinListingsLoading, setOnRentMashinListingsLoading] =
    React.useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AssetList = [
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["Available"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
  ];

  React.useEffect(() => {
    GetSettingByKey();
  }, []);

  const GetSettingByKey = async () => {
    setSettingValueLoader(true);
    setDashboardLoader(true);
    dashboardService
      .GetSettingByKey()
      .then((response) => {
        if (response.statusCode === 200) {
          setSettingValueLoader(false);
          setSettingValue(response?.data?.SettingValue.split(","));
        } else {
          setSettingValueLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const DashboardCardBox = ({ item, navigationColor, onClick, className }) => {
    return (
      <Box
        className={`Dashboard_Asset_Card ${className}`}
        onClick={() => onClick(item)}
        sx={{ position: "relative" }}
      >
        {item.StoreCode && item?.StoreColourCode && (
          <Box
            sx={{
              position: "absolute",
              background: item?.StoreColourCode,
              borderRadius: "50%",
              bottom: "-10px",
              height: "36px",
              width: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              left: "-10px",
            }}
          >
            {item.StoreCode}
          </Box>
        )}
        <Grid container spacing={0}>
          <Grid
            item
            xs={0.5}
            sx={{
              p: "5px",
              background: item?.MakeColorCode ?? "#c3c3c3",
              borderRadius: "5px 0 0 5px",
            }}
          ></Grid>
          <Grid item xs={6.5} sx={{ p: "5px" }}>
            {/* <Tooltip title={item?.CompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.CompanyName}
              </Box>
            </Tooltip> */}

            <Tooltip title={item?.UnitNumber} placement="top">
              <Box className="Dashboard_Asset_Card_Text Bold_text oneLineCSS">
                {item?.UnitNumber || ""}
              </Box>
            </Tooltip>
            <Tooltip title={item?.MakeName + item?.Model} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.MakeName + " " + item?.Model || ""}
              </Box>
            </Tooltip>

            {/* <Tooltip title={item?.Year} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.Year || ''}
              </Box>
            </Tooltip> */}

            <Tooltip title={item?.SerialNumber} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.SerialNumber || ""}
              </Box>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ background: "#f5f5f5", p: "5px" }}
            className="Dashboard_Asset_Card_Text_Highlight"
          >
            <Tooltip title={item?.CompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                {item?.CompanyName}
              </Box>
            </Tooltip>
            {item?.OnOpenRental === 1 ? (
              <>
                <Tooltip
                  title={item?.CurrentRentalCustomerName}
                  placement="top"
                >
                  <Box
                    className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                    style={{ textAlign: "left" }}
                  >
                    {item?.CurrentRentalCustomerName}
                  </Box>
                </Tooltip>
                <Tooltip title={item?.CurrentRentalContract} placement="top">
                  <Box
                    className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                    style={{ textAlign: "left" }}
                  >
                    {item?.CurrentRentalContract}
                  </Box>
                </Tooltip>
                {item?.ExpectedRentalReturnDate ? (
                  <Tooltip
                    title={moment(item?.ExpectedRentalReturnDate).format("ll")}
                    placement="top"
                  >
                    <Box
                      className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                      style={{ textAlign: "left" }}
                    >
                      {moment(item?.ExpectedRentalReturnDate).format("ll")}
                    </Box>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const GetAssetStatuses = async (e) => {
    setLoader({ ...loader, AssetStatusesLoader: true });
    dashboardService
      .GetAssetStatusesApi()
      .then((response) => {
        if (response.statusCode === 200) {
          setLoader({ ...loader, AssetStatusesLoader: false });
          let AssetStatuses = [];
          response?.data?.map((item, index) => {
            let newAssets = item;

            let assetsShow = DisplayStatuesLocalStorage?.filter(
              (DisplayStatuesItem) =>
                DisplayStatuesItem?.AssetStatusId === item?.AssetStatusId
            );
            newAssets = {
              ...newAssets,
              navigationColor: navigationColorColor?.[index]?.color,
              show: assetsShow?.[0]?.show ?? true,
            };
            AssetStatuses.push(newAssets);
          });
          setAssetStatuses(AssetStatuses);
          setAssetDisplayStatues(AssetStatuses);
        } else {
          setLoader({ ...loader, AssetStatusesLoader: false });
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));

    setLoader({ ...loader, AssetStatusesLoader: false });
  };

  const GetTierWiseAssetCategorizationSummaryDMS = async () => {
    setAssetCategorizationSummaryDMSLoader(true);
    dashboardService
      .GetTierWiseAssetCategorizationSummaryDMSAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setAssetCategorizationSummaryDMSLoader(false);
          setAssetTypeList(response.data);
        } else {
          setAssetCategorizationSummaryDMSLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  // ---------------------------------------------

  const handelSelectAssetTypeList = async (item, Type) => {
    resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        ShowBackBTN: true,
      });
    } else if (Type?.Type === "CategoryDetails") {
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };

  const handelSelectAssetTypeListBackFun = () => {
    if (
      assetList?.selectAssetDetails &&
      Object.keys(assetList?.selectAssetDetails).length !== 0 &&
      assetList?.tierCount === 0
    ) {
      setAssetList({
        ...assetList,

        ShowTitle: "",
        selectAssetDetails: {},
        ShowBackBTN: false,
      });
    } else if (assetList?.tierCount !== 0) {
      switch (assetList?.tierCount) {
        case 0:
          return setAssetList({
            ...assetList,
            ShowTitle: "",
            CategoryDetails: {},
            Tier1Select: {},
            Tier1Details: [],
            TierData: false,
          });
        case 1:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.selectAssetDetails?.AssetTypeName,
            Tier1Select: {},
            CategoryDetails: {},
            Tier2Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: false,
          });
        case 2:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.CategoryDetails?.CategoryName,
            Tier2Select: {},
            Tier1Select: {},
            Tier3Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 3:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier1Select?.Tier1Name,
            Tier3Select: {},
            Tier2Select: {},
            Tier4Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 4:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier2Select?.Tier2Name,
            Tier4Select: {},
            Tier3Select: {},
            Tier5Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });

        case 5:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier3Select?.Tier3Name,
            Tier4Select: {},
            Tier6Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        default:
          return;
      }
    }
  };

  const GetStores = () => {
    dashboardService
      .GetStoresAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setStoreList(response.data);
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const GetPagedAssets = (newPageDetails) => {
    setDashboardLoader(true);
    handleStop();
    let AssetStatuses = [];
    let AssetsPagination = [];
    let maxLength = 0;
    let recodeCount = {};
    if (!loader?.AssetStatusesLoader) {
      assetStatuses?.map((assetItem, index) => {
        if (assetItem?.show) {
          setLoader({ ...loader, [assetItem.AssetStatusName]: true });
          let payLoead = {
            Filter: {
              All: null,
              Company: null,
              SerialNumber: null,
              Model: null,
              Year: null,
              UnitNumber: null,
              Location: null,
              Status: [assetItem?.AssetStatusName],
              Category: [],
              TargetSellDate: null,
              LastSMUDate: null,
              CompanyIds: settingValue,
              TargetSaleDateIsNull: null,
              ParentAssetIdIsNULL: null,
              IndAvailableAssets: false,
              MinYear: null,
              MaxYear: null,
              MinSMU: null,
              MaxSMU: null,
              Make: null,
              StatusId: null,
              OnlyInventoryFlag: null,
              MakeName: null,
              AssetIds: null,
              StoreIds: [],
            },
            PageSize: showAssetCount,
            Sort: "ChangeDate_desc",
            IncludeCount: true,
          };

          if (assetList?.selectAssetDetails?.AssetTypeId !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                AssetTypeId: [assetList?.selectAssetDetails?.AssetTypeId],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, AssetTypeId: [] },
            };
          }

          if (assetList?.CategoryDetails?.CategoryName !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                CategoryName: [assetList?.CategoryDetails?.CategoryName],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, CategoryName: [] },
            };
          }

          if (storeCodeSelect) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                StoreIds: [storeCodeSelect],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, StoreIds: [] },
            };
          }

          if (assetList?.Tier1Select?.Tier1Name !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                Tier1Name: [assetList?.Tier1Select?.Tier1Name],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, Tier1Name: [] },
            };
          }

          if (assetList?.Tier2Select?.Tier2Name !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                Tier2Name: [assetList?.Tier2Select?.Tier2Name],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, Tier2Name: [] },
            };
          }

          if (assetList?.Tier3Select?.Tier3Name !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                Tier3Name: [assetList?.Tier3Select?.Tier3Name],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, Tier3Name: [] },
            };
          }

          if (assetList?.Tier4Select?.Tier4Name !== undefined) {
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                Tier4Name: [assetList?.Tier4Select?.Tier4Name],
              },
            };
          } else {
            payLoead = {
              ...payLoead,
              Filter: { ...payLoead?.Filter, Tier4Name: [] },
            };
          }

          if (newPageDetails !== undefined) {
            payLoead = {
              ...payLoead,
              PageNumber:
                newPageDetails?.[assetItem.AssetStatusName]?.PageDetails
                  ?.PageNumber,
            };
          } else {
            payLoead = {
              ...payLoead,
              PageNumber: 1,
            };
          }

          let loaders = loader;

          loaders = { ...loaders, [assetItem.AssetStatusName]: true };
          setLoader(loaders);
          dashboardService
            .GetPagedAssetsAPI(payLoead)
            .then((response) => {
              if (response.statusCode === 200) {
                loaders = { ...loaders, [assetItem.AssetStatusName]: false };
                setLoader(loaders);
                if (maxLength < response?.length?.TotalPages) {
                  maxLength = response?.length?.TotalPages;
                }
                setTotalPage(maxLength);
                recodeCount = {
                  ...recodeCount,
                  [assetItem.AssetStatusName]: {
                    totalAssetsRecode: response?.length?.TotalRecords,
                  },
                };
                AssetStatuses = {
                  ...AssetStatuses,
                  [assetItem.AssetStatusName]: {
                    data: response?.data,
                    totalCount: response?.data?.length,
                    start: 0,
                    end: showAssetCount,
                  },
                };

                AssetsPagination = {
                  ...AssetsPagination,

                  [assetItem.AssetStatusName]: {
                    PageDetails: response?.length,
                  },
                };
                setAssetStatusesListPagination(AssetsPagination);
                setAssetStatusesDetails(AssetStatuses);
                setTotalAssetsRecode(recodeCount);

                if (assetStatuses?.length === index + 1) {
                  setDashboardLoader(false);
                  handleStart();
                }
              } else {
                setLoader({ ...loader, [assetItem.AssetStatusName]: false });
                if (response.statusCode === 401) {
                  logOut();
                }
              }
            })
            .catch((error) => console.log("Login Api Error =====", error));
          setLoader({ ...loader, [assetItem.AssetStatusName]: false });
        } else {
          if (assetStatuses?.length === index + 1) {
            setDashboardLoader(false);
            handleStart();
          }
        }
      });
    }
  };
  // ----------------------------------------------

  const logOut = () => {
    navigate("/");
    localStorage.removeItem("ACCESS_TOKENS");
    localStorage.removeItem("StoreCode");
    localStorage.removeItem("DisplayStatues-V1");
    localStorage.removeItem("rowPerPage");
    localStorage.removeItem("rowPerPageInDR");
    localStorage.removeItem("DisplayStatuesInDR");
    localStorage.removeItem("StoreCodeInDR");
    localStorage.removeItem("SELECT_BOARD");
  };
  const Delivery_Receipt_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "delivery-receipt");
    navigate("/delivery-receipt");
  };

  const Dashboard_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "dashboard-c2");
    navigate("/dashboard-v2");
  };

  React.useEffect(() => {
    GetAssetStatuses();
    GetTierWiseAssetCategorizationSummaryDMS();
  }, []);

  React.useEffect(() => {
    setDashboardLoader(true);
    if (!settingValueLoader) {
      GetPagedAssets();
    }
  }, [
    storeCodeSelect,
    showAssetCount,
    settingValueLoader,
    loader?.AssetStatusesLoader,
    assetStatuses,
    assetList?.selectAssetDetails?.AssetTypeId,
    assetList?.CategoryDetails?.CategoryName,
    assetList?.Tier1Select?.Tier1Name,
    assetList?.Tier2Select?.Tier2Name,
    assetList?.Tier3Select?.Tier3Name,
    assetList?.Tier4Select?.Tier4Name,
  ]);

  React.useEffect(() => {
    GetStores();
  }, []);
  const handelpagination = () => {
    if (!dashboardLoader) {
      let newPageDetails = [];
      if (totalPage > currentPage) {
        assetStatuses?.map((assetItem) => {
          // if (
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.PageNumber <
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.TotalPages
          // ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber:
                  assetStatusesListPagination?.[assetItem.AssetStatusName]
                    ?.PageDetails?.PageNumber + 1,
              },
            },
          };
          // } else {
          //   newPageDetails = {
          //     ...newPageDetails,
          //     [assetItem.AssetStatusName]:
          //       assetStatusesListPagination?.[assetItem.AssetStatusName],
          //   };
          // }
        });

        setCurrentPage(currentPage + 1);
      } else {
        assetStatuses?.map((assetItem) => {
          // if (
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.PageNumber <
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.TotalPages
          // ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber: 1,
              },
            },
          };
          // }
        });

        setCurrentPage(1);
      }
      setAssetStatusesListPagination(newPageDetails);
      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
      setTimeAutoNextPage(!timeAutoNextPage);
    }
  };
  const handelpaginationBack = () => {
    let newPageDetails = [];
    if (currentPage > 1) {
      assetStatuses?.map((assetItem) => {
        if (
          assetStatusesListPagination?.[assetItem.AssetStatusName]?.PageDetails
            ?.PageNumber -
            1 >
          0
        ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber:
                  assetStatusesListPagination?.[assetItem.AssetStatusName]
                    ?.PageDetails?.PageNumber - 1,
              },
            },
          };
        } else {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]:
              assetStatusesListPagination?.[assetItem.AssetStatusName],
          };
        }
      });
      setAssetStatusesListPagination(newPageDetails);
      setCurrentPage(currentPage - 1);

      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelSelectStatuesModel = () => {
    setAssetDisplayStatues(assetStatuses);
    setSelectStatuesModel(false);
  };

  const handelSaveSelectStatuesModel = () => {
    localStorage.setItem(
      "DisplayStatues-V1",
      JSON.stringify(assetDisplayStatues)
    );
    setDisplayStatuesLocalStorage(assetDisplayStatues);
    setAssetStatuses(assetDisplayStatues);
    setSelectStatuesModel(false);
  };
  const nextBtnRef = React.useRef();
  const nextBtnRefMap = React.useRef();

  const resetPagination = () => {
    let newPageDetails = [];
    assetStatuses?.map((assetItem) => {
      newPageDetails = {
        ...newPageDetails,
        [assetItem.AssetStatusName]: {
          ...assetStatusesListPagination?.[assetItem.AssetStatusName],
          PageDetails: {
            ...assetStatusesListPagination?.[assetItem.AssetStatusName]
              ?.PageDetails,
            PageNumber: 1,
          },
        },
      };
    });
    setCurrentPage(1);
    setAssetStatusesListPagination(newPageDetails);
    return newPageDetails;
  };

  const handleStart = (autoPageNext) => {
    if (autoNextPage || autoPageNext) {
      const id = setInterval(() => {
        nextBtnRef.current?.click();
      }, 60000);
      setIntervalId(id);
    }
  };

  const handleStop = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };
  const handleStartAutoPageNavigationMap = () => {
    if (autoNextPageMap) {
      setAutoNextPageMap(false);
      handleStopMap();
    } else {
      setAutoNextPageMap(true);
      handleStartMap({ autoPageNext: true });
    }
  };
  const handleStartAutoPageNavigation = () => {
    if (autoNextPage) {
      setAutoNextPage(false);
      handleStop();
    } else {
      setAutoNextPage(true);
      handleStart({ autoPageNext: true });
    }
  };

  const handelAssetDisplayStatuesChange = (index) => {
    let newStatuesValue = [];
    assetDisplayStatues?.map((assetItem, assetIndex) => {
      if (index === assetIndex) {
        let item = assetItem;
        item = { ...item, show: !assetItem.show };
        newStatuesValue.push(item);
      } else {
        newStatuesValue.push(assetItem);
      }
    });
    setAssetDisplayStatues(newStatuesValue);
  };

  const handelChangeRowParPageValue = async (e) => {
    setRentedMapDataCurrontPage(1);
    if (!rentedMap) {
      localStorage.setItem("rowPerPage", e.target.value);
      setShowAssetCount(e.target.value);
    } else {
      localStorage.setItem("rowPerPage-Rental-Map", e.target.value);
      setShowAssetCountMap(e.target.value);
    }

    await resetPagination();
  };

  const handelStoreSelect = async (e) => {
    setStoreCodeSelect(e.target.value);
    await resetPagination();
    localStorage.setItem("StoreCode", e.target.value);
  };

  const [defaultProps, setDefaultProps] = React.useState({
    lat: "",
    lng: "",
  });

  const getOnlyOnRentMashinListings = async () => {
    setOnRentMashinListingsLoading(true);
    handleStopMap();
    let payLoead = {
      Filter: {
        All: null,
        Company: null,
        SerialNumber: null,
        Model: null,
        Year: null,
        UnitNumber: null,
        Location: null,
        Status: ["ON RENT"],
        Category: [],
        TargetSellDate: null,
        LastSMUDate: null,
        CompanyIds: settingValue,
        TargetSaleDateIsNull: null,
        ParentAssetIdIsNULL: null,
        IndAvailableAssets: false,
        MinYear: null,
        MaxYear: null,
        MinSMU: null,
        MaxSMU: null,
        Make: null,
        StatusId: null,
        OnlyInventoryFlag: null,
        MakeName: null,
        AssetIds: null,
        StoreIds: [],
      },
      PageSize: showAssetCountMap,
      PageNumber: rentedMapDataCurrontPage,
      Sort: "ChangeDate_desc",
      IncludeCount: true,
    };
    dashboardService
      .GetPagedAssetsAPI(payLoead)
      .then((response) => {
        if (response.statusCode === 200) {
          let center = "";

          response?.data.map((item) => {
            if (center.lat === undefined && center.lng === undefined) {
              center = {
                lat: item?.PositionLatitude,
                lng: item?.PositionLongitude,
              };
            }
          });
          handleStartMap({ autoPageNext: true });
          setRentedMapDataLength(response?.length);
          setDefaultProps(center);
          setSelectRentedMapItem({
            lat: center.lat,
            lng: center.lng,
            zoom: mapDefaultZoom,
          });
          setOnRentMashinListings(response?.data);
          setOnRentMashinListingsLoading(false);
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const handleMapView = () => {
    if (rentedMap) {
      setAutoNextPage(true);
      handleStart({ autoPageNext: true });
      handleStopMap();
      setRentedMapDataCurrontPage(1);
      setRentedMap(!rentedMap);
    } else {
      setAutoNextPage(false);
      handleStartMap({ autoPageNext: true });
      handleStop();
      setRentedMap(!rentedMap);
    }
  };
  const handelpaginationMap = () => {
    if (!onRentMashinListingsLoading) {
      if (rentedMapDataLength.TotalPages >= rentedMapDataCurrontPage + 1) {
        setRentedMapDataCurrontPage(rentedMapDataCurrontPage + 1);
      } else {
        setRentedMapDataCurrontPage(1);
      }
    }
  };
  const handelpaginationBackMap = () => {
    if (!onRentMashinListingsLoading) {
      if (rentedMapDataCurrontPage - 1 !== 0) {
        setRentedMapDataCurrontPage(rentedMapDataCurrontPage - 1);
      }
    }
  };
  const handleStartMap = (autoPageNext) => {
    if (autoNextPageMap || autoPageNext) {
      const id1 = setInterval(() => {
        nextBtnRefMap.current?.click();
      }, 60000);
      setIntervalIdMap(id1);
    }
  };
  const handleStopMap = () => {
    clearInterval(intervalIdMap);
    setIntervalIdMap(null);
  };

  React.useEffect(() => {
    if (rentedMap) {
      getOnlyOnRentMashinListings();
    }
  }, [rentedMap, rentedMapDataCurrontPage, showAssetCountMap]);

  const handelSetPopUPData = (item) => {
    setSelectPoPupMarker(item);
    setSelectRentedMapItem({
      lat: item.PositionLatitude,
      lng: item.PositionLongitude,
      zoom: 15,
    });
  };

  const handelCloseModel = (e) => {
    e.preventDefault();
    setSelectPoPupMarker(null);
  };
  return (
    <>
      <Modal
        open={selectStatuesModel}
        onClose={handelSelectStatuesModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#fff", fontWeight: "bold" }}
          >
            Display Statues
          </Typography>
          <FormGroup>
            {assetDisplayStatues?.map((assetItem, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assetItem?.show}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: "#fff",
                        },
                      }}
                      onChange={() => handelAssetDisplayStatuesChange(index)}
                    />
                  }
                  label={
                    <Box sx={{ color: "#fff" }}>
                      {assetItem?.AssetStatusName}
                    </Box>
                  }
                />
              );
            })}
          </FormGroup>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={handelSaveSelectStatuesModel}
            >
              Save
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
              }}
              onClick={handelSelectStatuesModel}
            >
              Cancal
            </button>
          </Box>
        </Box>
      </Modal>
      <Box className="Dashboard_Box">
        {/* ------------------ small Screen To Md Screen design --------------- */}
        <Box className="Dashboard_Sm_Screen">
          <Box className="Dashboard_Right_Navigation_Bar">
            <Box className="Dashboard_Navigation_Card_Box">
              {AssetTypeList?.map((item, index) => {
                return (
                  <>
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                      sx={{ background: "#ff000000" }}
                    >
                      <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        sx={{ margin: "0px", padding: "0px" }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_Sm"
                          sx={{ background: item?.AssetColorCode }}
                        >
                          <Box className="Dashboard_Navigation_Card_text">
                            {item?.AssetTypeName}
                          </Box>
                          <Box className="Dashboard_Navigation_Card_text text-bold">
                            {item?.AssetCount}
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {AssetList?.map((AssetListItem) => {
                          return (
                            AssetListItem?.Type === item?.AssetTypeName && (
                              <DashboardCardBox item={AssetListItem} />
                            )
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
            </Box>
            <Box className="Dashboard_Navigation_Setting_Box">
              <Box className="Dashboard_Navigation_Page_Text">
                Page{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  10
                </span>{" "}
                of{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  59
                </span>
              </Box>
              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <WestIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <PauseIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <EastIcon />
                </Box>
              </Box>

              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Option_close_Button">
                  <Box className="Dashboard_Navigation_close_Button">
                    <SettingsIcon sx={{ fontSize: "16px" }} />
                  </Box>
                  <span style={{ marginLeft: "5px" }}>Settings</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* ------------------ md To Big Screen design --------------- */}
        <Box className="Dashboard_Big_Screen">
          <Grid container spacing={1}>
            <Grid item xs={showSideBar ? 10 : 12}>
              {rentedMap ? (
                onRentMashinListingsLoading ? (
                  <div
                    className="dashboard_Box"
                    style={{ display: "flex", overflow: "auto", width: "100%" }}
                  >
                    <Box
                      sx={{
                        height: "90vh",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  </div>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={3} className="dashboard_Box_ON_RENT">
                      <Box sx={{ mb: "60px", minWidth: "300px" }}>
                        <Box>
                          <Box className="Dashboard_Asset_Heading">
                            ON RENT ({rentedMapDataLength?.TotalRecords ?? 0})
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              {onRentMashinListings.map((item) => {
                                return (
                                  <DashboardCardBox
                                    item={item}
                                    navigationColor={"e8ffe8"}
                                    onClick={(item) =>
                                      setSelectRentedMapItem({
                                        lat: item?.PositionLatitude,
                                        lng: item?.PositionLongitude,
                                        zoom: 20,
                                      })
                                    }
                                    className="cursor_pointer"
                                  />
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <div>
                        <div
                          style={{
                            height: "calc(100vh - 40px)",
                            width: "100%",
                          }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyC6dUNz2Vry4dIPxtTmK5hnk3zGMV7Yq1M",
                            }}
                            defaultCenter={{
                              lat: defaultProps.lat,
                              lng: defaultProps.lag,
                            }}
                            defaultZoom={6}
                            zoom={selectRentedMapItem.zoom}
                            center={{
                              lat: selectRentedMapItem.lat,
                              lng: selectRentedMapItem.lng,
                            }}
                            // options={{
                            //   streetViewControl: true,
                            //   mapTypeId: "satellite",
                            // }}
                            options={{
                              mapTypeControl: true,
                              streetViewControlOptions: true,
                              streetViewControl: true,
                              draggable: true, // make map draggable
                              zoomControlOptions: { position: 9 },
                              keyboardShortcuts: false, // disable keyboard shortcuts
                              scaleControl: true, // allow scale controle
                              scrollwheel: true, // allow scroll wheel
                            }}
                          >
                            {onRentMashinListings.map((item) => {
                              return (
                                item?.PositionLatitude !== undefined &&
                                item?.PositionLongitude !== undefined && (
                                  <AnyReactComponent
                                    lat={item?.PositionLatitude}
                                    lng={item?.PositionLongitude}
                                    item={item}
                                    text="My Marker"
                                    onClick={() => handelSetPopUPData(item)}
                                    selectPoPupMarker={selectPoPupMarker}
                                    closePopUp={(e) => handelCloseModel(e)}
                                  />
                                )
                              );
                            })}
                          </GoogleMapReact>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )
              ) : (
                <div
                  className="dashboard_Box"
                  style={{ display: "flex", overflow: "auto", width: "100%" }}
                >
                  {dashboardLoader ? (
                    <Box
                      sx={{
                        height: "90vh",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  ) : (
                    assetStatuses?.map((assetItem) => {
                      return (
                        assetItem?.show && (
                          <Box sx={{ mb: "60px", minWidth: "300px" }}>
                            <Box>
                              <Box className="Dashboard_Asset_Heading">
                                {assetItem?.AssetStatusName} (
                                {totalAssetsRecode?.[assetItem?.AssetStatusName]
                                  ?.totalAssetsRecode ?? 0}
                                )
                              </Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  {assetStatusesDetails?.[
                                    assetItem?.AssetStatusName
                                  ]?.data.map((item) => {
                                    return (
                                      <DashboardCardBox
                                        item={item}
                                        navigationColor={
                                          assetItem?.navigationColor
                                        }
                                      />
                                    );
                                  })}
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        )
                      );
                    })
                  )}
                </div>
              )}
            </Grid>
            {showSideBar &&
              (!sideBarMenu ? (
                <>
                  <Grid item xs={2} className="Dashboard_Right_Navigation_Bar">
                    <Box className="Dashboard_Navigation_Card_Box">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img src="./logo.png" alt="" className="logo-light" />
                      </Box>

                      <Box className="Dashboard_Navigation_Header">
                        <Box
                          display={"flex"}
                          width={"-webkit-fill-available"}
                          justifyContent={"space-between"}
                        >
                          <Tooltip
                            title={
                              !rentedMap
                                ? "Switch to Rental Map"
                                : "Switch to Rental Board"
                            }
                            placement="top"
                          >
                            <Box
                              className={`icon-container ${
                                rentedMap ? "flipped" : ""
                              }`}
                              onClick={handleMapView}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <Box className="icon">
                                <img
                                  src={!rentedMap ? RentalBord : RNONE}
                                  width={"100%"}
                                />
                              </Box>
                            </Box>
                          </Tooltip>

                          <Tooltip
                            title={"Switch to Delivery Receipt"}
                            placement="top"
                          >
                            <Box
                              onClick={() => {
                                setOpenSwitchModel(true);
                              }}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={DeliveryReceipt} width={"100%"} />
                            </Box>
                          </Tooltip>

                          <Tooltip
                            title={"Switch to Rental Board V2"}
                            placement="top"
                          >
                            <Box
                              onClick={() => {
                                setOpenSwitchV1Model(true);
                              }}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={RNTWO} width={"100%"} />
                            </Box>
                          </Tooltip>

                          <Tooltip title={"Log out"} placement="top">
                            <Box
                              onClick={handleClickOpen}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={LogOutIcon} width={"100%"} />
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box className="Dashboard_Navigation_Header">
                        {!sideBarMenu && (
                          <Box
                            className="Dashboard_Navigation_Back_Btn"
                            style={
                              !assetList?.ShowBackBTN
                                ? { opacity: "0.5", cursor: "not-allowed" }
                                : { opacity: "1", cursor: "pointer" }
                            }
                            onClick={handelSelectAssetTypeListBackFun}
                          >
                            <ArrowBackIcon />
                          </Box>
                        )}
                        <Tooltip title={assetList?.ShowTitle} placement="top">
                          <Box
                            sx={{
                              color: "#fff",
                              fontWeight: "bold",
                              p: "0 15px",
                            }}
                            className="oneLineCSS"
                          >
                            {assetList?.ShowTitle}
                          </Box>
                        </Tooltip>
                        <Box sx={{ display: "flex" }}></Box>
                      </Box>
                      {assetCategorizationSummaryDMSLoader ? (
                        <Box
                          sx={{
                            height: "80vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CustomLoader />
                        </Box>
                      ) : assetList?.selectAssetDetails &&
                        Object.keys(assetList?.selectAssetDetails).length ===
                          0 ? (
                        AssetTypeList?.map((item) => {
                          return (
                            <Box
                              className="Dashboard_Navigation_Card"
                              sx={{
                                background: "#009688",
                              }}
                              onClick={() =>
                                handelSelectAssetTypeList(item, {
                                  Type: "AssetList",
                                })
                              }
                            >
                              <Tooltip
                                title={item?.AssetTypeName}
                                placement="top"
                              >
                                <Box className="Dashboard_Navigation_Card_text">
                                  {item?.AssetTypeName}
                                </Box>
                              </Tooltip>
                            </Box>
                          );
                        })
                      ) : !assetList?.TierData ? (
                        assetList?.selectAssetDetails?.CategoryDetails?.map(
                          (CategoryItem) => {
                            return (
                              <Box
                                className="Dashboard_Navigation_Card"
                                sx={{
                                  background: "#009688",
                                }}
                                onClick={() =>
                                  handelSelectAssetTypeList(CategoryItem, {
                                    Type: "CategoryDetails",
                                  })
                                }
                              >
                                <Tooltip
                                  title={CategoryItem?.CategoryName}
                                  placement="top"
                                >
                                  <Box className="Dashboard_Navigation_Card_text">
                                    {CategoryItem?.CategoryName}
                                  </Box>
                                </Tooltip>
                              </Box>
                            );
                          }
                        )
                      ) : (
                        assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
                          (TierItem) => {
                            return (
                              <Box
                                className="Dashboard_Navigation_Card"
                                sx={{
                                  background: "#009688",
                                }}
                                onClick={() =>
                                  handelSelectAssetTypeList(TierItem, {
                                    Type: "TierData",
                                  })
                                }
                              >
                                <Tooltip
                                  title={
                                    TierItem?.[
                                      `Tier${assetList?.tierCount}Name`
                                    ]
                                  }
                                  placement="top"
                                >
                                  <Box className="Dashboard_Navigation_Card_text">
                                    {
                                      TierItem?.[
                                        `Tier${assetList?.tierCount}Name`
                                      ]
                                    }
                                  </Box>
                                </Tooltip>
                              </Box>
                            );
                          }
                        )
                      )}
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid item xs={2} className="Dashboard_Right_Navigation_Bar">
                  <Box
                    className="Dashboard_Navigation_Card_Box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                          src="./logo-light.svg"
                          alt=""
                          className="logo-light"
                        />
                      </Box>
                      <Box>
                        <Box className="Dashboard_Navigation_Header">
                          {sideBarMenu && (
                            <Box
                              className="Dashboard_Navigation_Back_Btn"
                              style={{ opacity: "0.5", cursor: "not-allowed" }}
                              onClick={handelSelectAssetTypeListBackFun}
                            >
                              <ArrowBackIcon />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Box sx={{ textAlign: "start", color: "#fff" }}>
                          Stores
                        </Box>
                        <Box className="custom-select">
                          <select
                            className="select_Box"
                            value={storeCodeSelect}
                            onChange={handelStoreSelect}
                          >
                            <option className="select_option" value={""}>
                              Select Store
                            </option>
                            ;
                            {storeList?.map((storeItem, index) => {
                              return (
                                <option
                                  className="select_option"
                                  value={storeItem?.StoreId}
                                >
                                  {storeItem?.Description}
                                </option>
                              );
                            })}
                          </select>
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{ textAlign: "start", color: "#fff" }}>
                          Recoeds per Column
                        </Box>
                        <Box className="custom-select">
                          <select
                            className="select_Box"
                            value={
                              !rentedMap ? showAssetCount : showAssetCountMap
                            }
                            onChange={handelChangeRowParPageValue}
                          >
                            <option className="select_option" value="10">
                              10
                            </option>
                            <option className="select_option" value="20">
                              20
                            </option>
                            <option className="select_option" value="50">
                              50
                            </option>
                            <option className="select_option" value="100">
                              100
                            </option>
                            <option className="select_option" value="200">
                              200
                            </option>
                            <option className="select_option" value="99999999">
                              All
                            </option>
                          </select>
                        </Box>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => setSelectStatuesModel(true)}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          Select Statuses
                        </Box>
                      </Box>

                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={handleMapView}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          {!rentedMap
                            ? "Switch to Rental Map"
                            : "Switch to Rental Board"}
                        </Box>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => {
                          setOpenSwitchModel(true);
                        }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{
                            color: "#fff",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          <img
                            src="./switch.svg"
                            alt=""
                            width={"20px"}
                            style={{ marginRight: "10px" }}
                          />
                          Switch to Delivery Receipt
                        </Box>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => {
                          setOpenSwitchV1Model(true);
                        }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{
                            color: "#fff",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          <img
                            src="./switch.svg"
                            alt=""
                            width={"20px"}
                            style={{ marginRight: "10px" }}
                          />
                          Switch to Rental Board V2
                        </Box>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={handleClickOpen}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          Log Out
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>

        {/*  --------------- Footer ------------------- */}
        <Box className="DashboardComponent_Footer">
          <Grid container spacing={0} sx={{ alignItems: "center" }}>
            <Grid item xs={10}>
              <Box className="Dashboard_Navigation_Setting_Box_Big_Screen">
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  {!rentedMap ? (
                    <Box
                      className="Dashboard_Navigation_Button cursor_pointer"
                      onClick={handleStartAutoPageNavigation}
                    >
                      {autoNextPage ? <PauseIcon /> : <PlayArrowIcon />}
                    </Box>
                  ) : (
                    <Box
                      className="Dashboard_Navigation_Button cursor_pointer"
                      onClick={handleStartAutoPageNavigationMap}
                    >
                      {autoNextPageMap ? <PauseIcon /> : <PlayArrowIcon />}
                    </Box>
                  )}
                </Box>
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  {!rentedMap ? (
                    <>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationBack}
                      >
                        <KeyboardArrowLeftIcon />
                      </Box>
                      <Box className="Dashboard_Navigation_Page_Text">
                        Page{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {currentPage}
                        </span>{" "}
                        of{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {totalPage}
                        </span>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpagination}
                        ref={nextBtnRef}
                      >
                        <KeyboardArrowRightIcon />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationBackMap}
                      >
                        <KeyboardArrowLeftIcon />
                      </Box>
                      <Box className="Dashboard_Navigation_Page_Text">
                        Page{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {rentedMapDataLength?.PageNumber}
                        </span>{" "}
                        of{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {rentedMapDataLength?.TotalPages}
                        </span>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationMap}
                        ref={nextBtnRefMap}
                      >
                        <KeyboardArrowRightIcon />
                      </Box>
                    </>
                  )}
                </Box>
                <Box></Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="Dashboard_Option_close_Button" />
                {!!showSideBar && !rentedMap && (
                  <Box>
                    {!sideBarMenu ? (
                      <Box
                        className="Dashboard_Option_close_Button"
                        onClick={() => setSideBarMenu(true)}
                      >
                        <Box className="Dashboard_Navigation_close_Button">
                          <SettingsIcon sx={{ fontSize: "16px" }} />
                        </Box>
                        <span style={{ margin: "0 10px" }}>Settings</span>
                      </Box>
                    ) : (
                      <Box
                        className="Dashboard_Option_close_Button"
                        onClick={() => setSideBarMenu(false)}
                      >
                        <Box className="Dashboard_Navigation_close_Button">
                          <CloseIcon sx={{ fontSize: "16px" }} />
                        </Box>
                        <span style={{ margin: "0 10px" }}>Close</span>
                      </Box>
                    )}
                  </Box>
                )}
                {showSideBar ? (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowRightIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowLeftIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openSwitchModel}
          onClose={() => setOpenSwitchModel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to switch to Delivery Receipt ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setOpenSwitchModel(false)}
            >
              No
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={Delivery_Receipt_Navigate}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSwitchV1Model}
          onClose={() => setOpenSwitchV1Model(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to switch to Rental Board V2 ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setOpenSwitchV1Model(false)}
            >
              No
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={Dashboard_Navigate}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to logout ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={handleClose}
            >
              Cancal
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={logOut}
            >
              Log Out
            </button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default DashboardComponent;
